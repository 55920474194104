import styled from 'styled-components';
import {
  MenuItem,
  Select,
  InputLabel,
  Box,
  ListSubheader,
  Typography,
} from '@mui/material';
import { neutrals } from 'utils/styles/color';

export const StyledSelect = styled(Select)`
  && {
    font-size: 1rem;
    font-weight: 400;
    color: ${neutrals[700]} !important;
    .MuiSelect-select {
      font-family: 'Poppins', sans-serif;
      padding: 0 !important;
      color: ${neutrals[700]};
      text-align: center;
      &:focus {
        background-color: transparent;
      }
    }
  }
`;

export const StyledLabel = styled(InputLabel)`
  && {
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    transform: translate(50%, 17px);
    color: ${neutrals[500]} !important;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${neutrals[700]} !important;

    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
`;

export const StyledSelectValue = styled(Box)`
  && {
    display: flex;
    gap: 4px;
    transform: translateY(3px);
    padding: 0 8px;

    color: ${neutrals[800]};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    span:nth-of-type(2) {
      color: ${neutrals[500]};
    }
  }
`;

export const StyledListSubheader = styled(ListSubheader)`
  && {
    display: flex;
    padding: 4px 8px;

    color: ${neutrals[400]};
    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const StyledGroupedMenuItem = styled(MenuItem)`
  && {
    padding: 0;
  }
`;

export const StyledItemText = styled(Typography)<{
  $padding?: string;
}>`
  && {
    padding: ${({ $padding }) =>
      !$padding ? '5px 24px !important' : $padding};
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
