/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useEffect,
  useState,
  useCallback,
  ReactElement,
  useMemo,
} from 'react';
import { CardContent, CardHeader, Box, Typography, Stack } from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import axios from 'axios';
import { ReactComponent as SettingsIcon } from 'assets/icons/edit-settings.svg';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  checkIsProjectInvoiceDetailsEmpty,
  prepareProjectStructure,
  projectInitialData,
} from 'pages/CompanyPage/helpers';
import {
  CloudOpsProjectsType,
  InvoiceChartProps,
  QuickbooksProjectType,
} from './types';
import { AddProject, CloudOpsCard } from './CompanyPage.styles';
import { palette } from 'utils/styles/variables';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import Table from 'molecules/Table';
import { StyledCellText2 } from 'molecules/Table/styles/Text.styles';
import { StyledCellContainer } from 'molecules/Table/styles';
import CloudOpsProjectDialog from 'organisms/ProjectDialogs/CloudOpsProjectDialog';
import CloudOpsProjectDetailsDialog from 'organisms/ProjectDialogs/CloudOpsProjectDetailsDialog';
import CloudOpsProjectSyncDialog from 'organisms/ProjectDialogs/CloudOpsProjectSyncDialog';
import { neutrals } from 'utils/styles/color';
import { SUPER_ADMIN } from 'utils/constants/roles';
import ZohoProjectSyncDialog from 'organisms/ProjectDialogs/ZohoProjectSyncDialog';

const CloudOpsComp = ({ companyHsId }: InvoiceChartProps) => {
  const [companyProjects, setCompanyProjects] = useState<
    CloudOpsProjectsType[]
  >([]);

  const { value: user } = useBaoSelector((state) => state.user);

  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showQuickbookSyncDialog, setShowQuickbookSyncDialog] =
    useState<boolean>(false);
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  const [currency, setCurrency] = useState<
    keyof typeof currencyIcon | undefined
  >();
  const [projectInfo, setProjectInfo] =
    useState<CloudOpsProjectsType>(projectInitialData);

  const getCompanyProjects = useCallback(async () => {
    if (companyHsId) {
      const projects = await axios
        .get(
          `${process.env.REACT_APP_USER_SERVICE}/projects?company_hs_id=${companyHsId}`
        )
        .then((res) => {
          const { data } = res;

          return data.projects
            .sort(
              (a: CloudOpsProjectsType, b: CloudOpsProjectsType) => a.id - b.id
            )
            .map(prepareProjectStructure);
        });

      setCompanyProjects(projects);
      return projects;
    }

    return [];
  }, [companyHsId]);

  const handleCreateClose = useCallback(
    async (newProject?: CloudOpsProjectsType) => {
      if (!newProject) {
        setShowCreateDialog(false);
        return;
      }

      const prjs: CloudOpsProjectsType[] = await getCompanyProjects();

      if (!prjs.length) {
        setShowCreateDialog(false);
        return;
      }

      const { id_str } = newProject;
      const prj = prjs.find((item) => item.id_str === id_str);

      if (!prj) {
        setShowCreateDialog(false);
        return;
      }

      setProjectInfo(prj);

      setShowCreateDialog(false);

      setShowDetailsDialog(true);
    },
    [getCompanyProjects]
  );

  const handleDetailsClose = useCallback(() => {
    setProjectInfo(projectInitialData); // clear selected project details
    setShowDetailsDialog(false);

    getCompanyProjects();
  }, [getCompanyProjects]);

  const handleSubmitSyncDialog = useCallback(async () => {
    const prjs: CloudOpsProjectsType[] = await getCompanyProjects();

    if (!prjs.length) {
      setShowSyncDialog(false);
      return;
    }

    const { quickbook_project_id } = projectInfo;
    const prj = prjs.find(
      (item) => item.quickbook_project_id === quickbook_project_id
    );

    if (!prj) {
      setShowSyncDialog(false);
      return;
    }

    setProjectInfo(prj);

    setShowSyncDialog(false);
    setShowQuickbookSyncDialog(false);

    setShowDetailsDialog(true);
  }, [getCompanyProjects, projectInfo]);

  const handleCancelSyncDialog = useCallback(() => {
    setShowSyncDialog(false);
  }, []);

  useEffect(() => {
    getCompanyProjects();
  }, [getCompanyProjects]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company/${companyHsId}`)
      .then((response) => {
        const { data } = response;
        setCurrency(data.currency);
      });
  }, [companyHsId]);

  const getProjectMarkup = (
    projectData: CloudOpsProjectsType
  ): ReactElement => {
    const { id, company_hs_id, quickbook_project_id, info, status } =
      projectData;
    const color: string =
      quickbook_project_id && company_hs_id
        ? palette.success[500]
        : palette.error[700];
    const statusColor =
      status === 'active' ? neutrals[700] : palette.error[700]; // '#F48319';

    return (
      <span>
        <span
          style={{
            display: 'inline-flex',
            color,
          }}
        >
          {info.suffix || ''}
        </span>
        {status && id && (
          <>
            <span style={{ color: `${color} !important` }}>
              {status && (info.suffix || info.prefix) ? ', ' : ''}
            </span>
            <span
              style={{
                display: 'inline-flex',
                color: statusColor,
              }}
            >
              {status === 'active' ? 'Active' : 'Deactivated'}
            </span>
          </>
        )}
      </span>
    );
  };

  const getProjectTooltip = (projectData: CloudOpsProjectsType): string => {
    // if project is added from zstream via add new project button
    if (projectData.company_hs_id && !projectData.quickbook_project_id) {
      if (!checkIsProjectInvoiceDetailsEmpty(projectData)) {
        return `Project ${projectData.name} is not synced with the corresponding QuickBooks project. As there are lack of invoice details zConnect will not generate invoices automatically for this project.`;
      }

      return `Project ${projectData.name} is not synced with the corresponding QuickBooks project. Invoices for this project will be automatically generated according to the monthly report.`;
    }

    // if project is caught from Quickbooks
    if (!projectData.company_hs_id && projectData.quickbook_project_id) {
      return `Project ${projectData.quickbook_project_name} is not synced with the corresponding project in zConnect.`;
    }

    // if project has valid invoice details and project is synced with Stream and Quickbooks
    if (
      projectData.company_hs_id &&
      projectData.quickbook_project_id &&
      checkIsProjectInvoiceDetailsEmpty(projectData)
    ) {
      return `Project CloudOps ${projectData.name}  is synced between zStream-zConnect-QuickBooks. Invoices for this project will be automatically generated monthly according to the monthly report.`;
    }

    // if project has at least one empty invoice details field and project is synced with Stream and Quickbooks
    if (
      projectData.company_hs_id &&
      projectData.quickbook_project_id &&
      !checkIsProjectInvoiceDetailsEmpty(projectData)
    ) {
      return `Project ${projectData.name} is synced between zStream-zConnect-QuickBooks. As there are lack of invoice details zConnect will not generate invoices automatically for this project.`;
    }

    return '';
  };

  const columns = useMemo(
    () => [
      {
        key: 'name',
        label: 'Project name',
        sortable: false,
        width: '45%',
        render: (project: CloudOpsProjectsType) => {
          const { name, quickbook_project_name } = project;
          return (
            <StyledCellText2>
              {name || quickbook_project_name || ''}
            </StyledCellText2>
          );
        },
      },
      {
        key: 'customType',
        label: 'Type',
        sortable: false,
        width: '15%',
        render: (project: CloudOpsProjectsType) => (
          <StyledCellText2>{project?.customType || ''}</StyledCellText2>
        ),
      },
      {
        key: 'info',
        label: 'Status',
        sortable: false,
        width: '45%',
        render: (project: CloudOpsProjectsType) => {
          const { info } = project;

          return (
            <StyledCellText2 title={getProjectTooltip(project)}>
              {info?.prefix || ''}
              {!info.suffix || !info.prefix ? '' : ', '}
              {getProjectMarkup(project)}
            </StyledCellText2>
          );
        },
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        width: '10%',
        render: (project: CloudOpsProjectsType) => (
          <StyledCellContainer $right $hStack>
            <SettingsIcon
              onClick={() => {
                setProjectInfo(project);
                if (!project.id && project.quickbook_project_id) {
                  setShowQuickbookSyncDialog(true);
                } else if (!project.id) {
                  setShowSyncDialog(true);
                } else {
                  setShowDetailsDialog(true);
                }
              }}
              style={{
                color: '#6F9FE9',
                position: 'relative',
                cursor: 'pointer',
              }}
            />
          </StyledCellContainer>
        ),
      },
    ],
    []
  );

  const handleUpdateModal = async () => {
    const projects = await getCompanyProjects();

    const updatedProject = projects.find(
      (project: CloudOpsProjectsType) => project.id_str === projectInfo.id_str
    );

    if (updatedProject) {
      setProjectInfo(updatedProject);
    }
  };

  return (
    <CloudOpsCard>
      <CardHeader
        title={
          <Stack className="custom-header">
            <Typography variant="h6">Projects</Typography>
            {user.userType === SUPER_ADMIN && (
              <AddProject onClick={() => setShowCreateDialog(true)}>
                Add new project
              </AddProject>
            )}
          </Stack>
        }
        avatar={<CardAvatar />}
      />
      <CardContent sx={{ padding: '24px 8px' }}>
        <Box
          sx={{
            maxHeight: '275px',
            overflow: companyProjects.length > 6 ? 'hidden scroll' : 'hidden',
            paddingRight: '5px',
            '.MuiTableHead-root .MuiTypography-root': {
              color: neutrals[500],
            },
          }}
        >
          <Table
            stickyHeader
            emptyText=""
            idKey="id"
            size="small"
            hover={false}
            columns={columns}
            data={companyProjects}
          />
        </Box>
      </CardContent>

      {/* Create project dialog */}
      {showCreateDialog && (
        <CloudOpsProjectDialog
          currency={currency}
          project={projectInfo}
          companyHsId={companyHsId}
          onClose={handleCreateClose}
        />
      )}

      {/* Project details dialog */}
      {showDetailsDialog && (
        <CloudOpsProjectDetailsDialog
          currency={currency}
          project={projectInfo}
          companyHsId={companyHsId}
          onClose={handleDetailsClose}
          onUpdate={handleUpdateModal}
        />
      )}

      {/* Sync project dialog */}
      {showSyncDialog && (
        <CloudOpsProjectSyncDialog
          currency={currency}
          project={projectInfo as QuickbooksProjectType}
          companyHsId={companyHsId}
          onClose={handleCancelSyncDialog}
          onSync={handleSubmitSyncDialog}
        />
      )}

      {/* Quickbook project sync dialog */}
      {showQuickbookSyncDialog && (
        <ZohoProjectSyncDialog
          project={projectInfo}
          companyHsId={companyHsId}
          onClose={() => setShowQuickbookSyncDialog(false)}
          onSync={handleSubmitSyncDialog}
        />
      )}
    </CloudOpsCard>
  );
};

export default CloudOpsComp;
